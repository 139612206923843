import * as React from 'react';
import { CardDescription, CardTitle } from 'components/Card';

function useOptions(t) {
  const options = [
    {
      value: 'voiceLift',
      content: (
        <>
          <CardTitle>{t('applications:voiceLift')}</CardTitle>
          <CardDescription>{t('applications:voiceLiftDesc')}</CardDescription>
        </>
      ),
    },
    {
      value: 'cameraTracking',
      content: (
        <>
          <CardTitle>{t('applications:cameraTracking')}</CardTitle>
          <CardDescription>
            {t('applications:cameraTrackingDesc')}
          </CardDescription>
        </>
      ),
    },
    {
      value: 'soundReinforcement',
      content: (
        <>
          <CardTitle>{t('applications:soundReinforcement')}</CardTitle>
          <CardDescription>
            {t('applications:soundReinforcementDesc')}
          </CardDescription>
        </>
      ),
    },
  ];
  return options;
}

export default useOptions;
