import * as React from 'react';
// import PropTypes from 'prop-types';

import CardArea from 'components/CardArea';
import Layout from 'components/Layout';
import Header from 'components/Header';
import Title from 'components/Title';

import { getSearch, gtmApplications, gtmGenerateSolution } from 'utils/helpers';
import { graphql } from 'gatsby';
import routes from 'utils/routes';
import useIsMobile from 'hooks/useIsMobile';
import useOptions from 'pageComponents/applications/useOptions';
import { useQueryParam, ArrayParam } from 'use-query-params';
import { useTranslation } from 'gatsby-plugin-react-i18next';

function Applications(props) {
  const { t } = useTranslation(['applications', 'common', 'nav']);
  const isMobile = useIsMobile();
  const options = useOptions(t);
  const [selectedValue, setSelectedValue] = useQueryParam(
    'applications',
    ArrayParam
  );

  React.useEffect(() => {
    // On unmount - send gtm event
    return () => {
      gtmApplications();
    };
    // eslint-disable-next-line
  }, []);

  function handleSelect(val) {
    let newValue;
    if (!selectedValue) {
      return setSelectedValue([val]);
    }
    if (selectedValue.includes(val)) {
      newValue = selectedValue.filter((el) => el !== val);
    } else {
      newValue = selectedValue.concat(val);
    }
    setSelectedValue(newValue);
  }

  const navProps = {
    backButton: {
      to: `${routes.microphonePlacement}${getSearch()}`,
    },
    nextButton: {
      children: isMobile
        ? t('certifiedSolutions:submitButtonMobile')
        : t('certifiedSolutions:submitButton'),
      onClick: () => gtmGenerateSolution(),
      to: `${routes.recommendations}${getSearch()}`,
    },
  };

  return (
    <Layout navProps={navProps}>
      <Header pageNumber={5} t={t} />
      <Title
        instruction={t('common:selectAll')}
        maxWidth={744}
        subTitle={t('common:globalSubTitle')}
        title={t('applications:title')}
        tooltip={t('applications:titleTooltip')}
      />
      <CardArea
        columns={3}
        handleSelect={handleSelect}
        selectedValue={selectedValue || []}
        multiselect
        values={options}
      />
    </Layout>
  );
}

Applications.propTypes = {};

Applications.defaultProps = {};

export default Applications;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["certifiedSolutions", "common", "nav", "applications"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
